import React from "react";

export type InscriptionTimeRendererProps = {
  time: number;
};

const InscriptionTimeRenderer = ({ time }: InscriptionTimeRendererProps) => {
  let seconds = time;
  const minutes = Math.trunc(seconds / 60);
  seconds -= minutes * 60;
  const formatMinutes = (m: number) => (m > 0 ? "" + m + ":" : "");
  const formatSeconds = (s: number) =>
    s > 10 ? s.toFixed(2) : "0" + s.toFixed(2);
  return (
    <>
      {formatMinutes(minutes)}
      {formatSeconds(seconds)}
    </>
  );
};

export default InscriptionTimeRenderer;
