import React, { useEffect, useState } from "react";
import { LeveradeParticipant } from "../leverade";
import {
  createInitialSeassonsMap,
  distancesT,
  getKeys,
  seassonsMap,
  stylesT,
} from "../values";
import InscriptionTimeRenderer from "./tablecellrenderers/InscriptionTimeRenderer";
import { styleMap } from "../models/Style";
import { EventResult } from "../EventResult";
import { LeveradeWithCompetispyBackupEventResultController } from "../LeveradeWithCompetispyBackupEventResultController";
import { EventResultController } from "../EventResultController";
import { useAuth0 } from "@auth0/auth0-react";
import { Col, Dropdown, Row } from "react-bootstrap";

type LeveradeTimeRendererProps = {
  time: number;
};

const LeveradeTimeRenderer = ({ time }: LeveradeTimeRendererProps) => {
  return <>{time !== 0 ? <InscriptionTimeRenderer time={time} /> : <></>}</>;
};

type SpyTableElementProps = {
  eventResultController: EventResultController;
  event: {
    distance: distancesT;
    style: stylesT;
  };
  pool: 25 | 50;
  season: keyof seassonsMap<void>;
};

const SpyTableElement = ({
  eventResultController,
  event,
  pool,
  season,
}: SpyTableElementProps) => {
  const [time, setTime] = useState(0);
  const [date, setDate] = useState("");
  const setEventResult = (eventResult: EventResult | undefined): void => {
    if (eventResult) {
      setDate(eventResult.date);
      setTime(eventResult.time);
    }
  };
  useEffect(() => {
    eventResultController.get_best_event_result(
      event.distance,
      event.style,
      pool,
      null,
      season,
      setEventResult
    );
  }, [eventResultController, pool, event, season]);
  return (
    <>
      {time !== 0 ? (
        <>
          <td>
            <LeveradeTimeRenderer time={time} />
          </td>
          <td>{date}</td>
        </>
      ) : (
        <>
          <td />
          <td />
        </>
      )}
    </>
  );
};

type SpyTableProps = {
  name: string;
  surname: string;
  born_year: number;
};

const SpyTable = ({ name, surname, born_year }: SpyTableProps) => {
  const voidSeasson: seassonsMap<void> =
    createInitialSeassonsMap<void>(undefined);
  const [season, setSeason] = useState<keyof seassonsMap<void>>("all");
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();
  const eventResultController = isAuthenticated
    ? new LeveradeWithCompetispyBackupEventResultController(
        name,
        surname,
        born_year,
        () => {
          return getAccessTokenSilently();
        }
      )
    : new LeveradeParticipant(name, surname, born_year);
  type eventsType = { distance: distancesT; style: keyof styleMap<void> }[];
  const freeEvents: eventsType = ([50, 100, 200, 400, 800, 1500] as const).map(
    (d) => {
      return { distance: d, style: "libre" };
    }
  );
  const butterflyEvents: eventsType = ([50, 100, 200] as const).map((d) => {
    return { distance: d, style: "mariposa" };
  });
  const backEvents: eventsType = ([50, 100, 200] as const).map((d) => {
    return { distance: d, style: "espalda" };
  });
  const breastEvents: eventsType = ([50, 100, 200] as const).map((d) => {
    return { distance: d, style: "braza" };
  });
  const medleyEvents: eventsType = ([100, 200, 400] as const).map((d) => {
    return { distance: d, style: "estilos" };
  });
  const eventsList = [
    ...freeEvents,
    ...butterflyEvents,
    ...backEvents,
    ...breastEvents,
    ...medleyEvents,
  ];
  const handleSeasonChange = (newSeason: keyof seassonsMap<void>) => {
    setSeason(newSeason);
  };
  const seasonStyle = {
    height: "auto",
    marginTop: "auto",
    marginLeft: "10px",
  };
  return (
    <>
      <Row>
        <Col md="auto">
          <Dropdown className="d-inline mx-2">
            <Dropdown.Toggle id="dropdown-autoclose-true" variant="secondary">
              Temporada
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {getKeys(voidSeasson).map((ss) => (
                <Dropdown.Item
                  key={ss}
                  className="dropdown-item"
                  onClick={() => handleSeasonChange(ss)}
                >
                  {ss}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </Col>
        <Col md="auto">
          <div className="form-group form-row" style={seasonStyle}>
            {season}
          </div>
        </Col>
      </Row>
      <table id="tabla_tiempos" className="display table">
        <thead>
          <tr>
            <td rowSpan={2} />
            <th colSpan={2}>25m</th>
            <th colSpan={2}>50m</th>
          </tr>
          <tr>
            <th>Tiempo</th>
            <th>Fecha</th>
            <th>Tiempo</th>
            <th>Fecha</th>
          </tr>
        </thead>
        <tbody>
          {eventsList.map((event) => {
            return (
              <tr
                key={
                  event.distance.toString() +
                  event.style +
                  eventResultController.get_unique_id() +
                  season
                }
              >
                <th scope="row">
                  {event.distance}m {event.style.toLowerCase()}
                </th>
                <SpyTableElement
                  eventResultController={eventResultController}
                  event={event}
                  pool={25}
                  season={season}
                />
                {event.distance === 100 && event.style === "estilos" ? (
                  <>
                    <td />
                    <td />
                  </>
                ) : (
                  <SpyTableElement
                    eventResultController={eventResultController}
                    event={event}
                    pool={50}
                    season={season}
                  />
                )}
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
};

export default SpyTable;
