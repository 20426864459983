import React from "react";
import { Row } from "@tanstack/react-table";
import { SwimmerForTable } from "../../values";
import { Button } from "react-bootstrap";

export type SpyRendererValuesType = {
  leverade?: {
    name: string;
    surname: string;
  };
  born_year: number;
};

export type SpyRendererProps = {
  row: Row<SwimmerForTable>;
  values: SpyRendererValuesType;
};

const SpyRenderer = ({ row, values }: SpyRendererProps) => {
  return values.leverade ? (
    <>
      <span
        role={"button"}
        onClick={(_) => {
          row.toggleExpanded();
        }}
      >
        {row.getIsExpanded() ? "👇" : "👉"}
      </span>
    </>
  ) : (
    <></>
  );
};

export default SpyRenderer;
