export const styles = ["Mariposa", "Espalda", "Braza", "Libre", "Estilos"];
export type styleT = "fly" | "back" | "breast" | "free" | "medley";

export const medleyRelayStyles: styleT[] = ["back", "breast", "fly", "free"];
export const freeRelayStyles: styleT[] = ["free", "free", "free", "free"];

export const styleToRepr = (style: styleT): string => {
  switch (style) {
    case "free":
      return "crol";
    case "fly":
      return "mariposa";
    case "breast":
      return "braza";
    case "back":
      return "espalda";
    case "medley":
      return "estilos";
  }
};

export type styleMap<T> = {
  braza: T;
  espalda: T;
  estilos: T;
  libre: T;
  mariposa: T;
};

export const styleToStyleMapKey = (style: styleT): keyof styleMap<void> => {
  switch (style) {
    case "free":
      return "libre";
    case "fly":
      return "mariposa";
    case "breast":
      return "braza";
    case "back":
      return "espalda";
    case "medley":
      return "estilos";
  }
};
