import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { championships_api_uri } from "../values";

import { useAuth0 } from "@auth0/auth0-react";
import { Navbar, Nav, Button, Container, NavDropdown } from "react-bootstrap";

type NavBarProps = {
  setChampionship: (championship: string) => void;
};

const NavBar = ({ setChampionship }: NavBarProps) => {
  const { user, isAuthenticated, loginWithRedirect, logout } = useAuth0();

  const logoutWithRedirect = () =>
    logout({
      returnTo: window.location.origin,
    });

  const [championships, setChampionships] = useState([]);
  const [navChampionship, setNavChampionship] = useState("");

  const history = useHistory();

  const handleChampionshipChange = (event: React.MouseEvent<HTMLElement>) => {
    const newChampionship = event.currentTarget.textContent;
    if (newChampionship == null) {
      return;
    }
    setChampionship(newChampionship);
    setNavChampionship(newChampionship);
    const oldLocation = window.location.pathname;
    if (oldLocation.startsWith("/swimmertimes")) {
      return;
    }
    const availableParts = [
      "eventsearch",
      "swimmersearch",
      "advancedsearch",
      "test",
    ];
    const locationParts = oldLocation.split("/").slice(1);
    if (!availableParts.includes(locationParts[0])) {
      return;
    }
    const newLocation =
      "/" +
      locationParts[0] +
      "/" +
      encodeURIComponent(newChampionship) +
      "/" +
      locationParts.slice(2).join("/");
    history.push(newLocation);
  };

  const getUrlWithChampionship = (page: string) => {
    return "/" + page + "/" + encodeURI(navChampionship);
  };

  useEffect(() => {
    (async () => {
      fetch(championships_api_uri)
        .then((res) => res.json())
        .then(
          (result) => {
            setChampionships(result);
            for (const champ of result) {
              if (
                window.location.pathname.includes(encodeURIComponent(champ))
              ) {
                setChampionship(champ);
                setNavChampionship(champ);
                return;
              }
            }
            setChampionship(result[0]);
            setNavChampionship(result[0]);
          },
          (error) => {
            console.log(error);
          }
        );
    })();
  }, []);
  return (
    <Navbar expand="lg" bg="light" data-bs-theme="light">
      <Container>
        <Navbar.Brand as={Link} to="/#">
          CompetiSpy
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll">
          <Nav navbarScroll>
            <Nav.Link as={Link} to={getUrlWithChampionship("eventsearch")}>
              Prueba
            </Nav.Link>
            <Nav.Link as={Link} to={getUrlWithChampionship("swimmersearch")}>
              Nadador
            </Nav.Link>
            <Nav.Link as={Link} to={getUrlWithChampionship("advancedsearch")}>
              Búsqueda Avanzada
            </Nav.Link>
            <NavDropdown title="Campeonato" id="dropDownCampeonato">
              {championships.map((championship) => (
                <NavDropdown.Item
                  key={championship}
                  onClick={handleChampionshipChange}
                >
                  {championship}
                </NavDropdown.Item>
              ))}
            </NavDropdown>
            <Nav.Link as={Link} to="/swimmertimes">
              Mejores marcas
            </Nav.Link>
            {isAuthenticated && (
              <Nav.Link as={Link} to={getUrlWithChampionship("relayhelper")}>
                Relay helper
              </Nav.Link>
            )}
          </Nav>
          <Nav className="ms-auto">
            {!isAuthenticated && (
              <Button
                id="qsLoginBtn"
                variant={"outline-dark"}
                onClick={() => loginWithRedirect()}
              >
                Log in
              </Button>
            )}
            {isAuthenticated && (
              <NavDropdown
                title={
                  <img
                    src={user?.picture}
                    alt="Profile"
                    className="nav-user-profile rounded-circle"
                    width="50"
                  />
                }
              >
                <NavDropdown.Header>{user?.name}</NavDropdown.Header>
                <NavDropdown.Item
                  onClick={() => logoutWithRedirect()}
                  id="qsLogoutBtn"
                >
                  Salir
                </NavDropdown.Item>
              </NavDropdown>
            )}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default NavBar;
