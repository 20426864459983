import React, { useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import CompetyTableController from "./CompetyTableController";
import { swimmerSearchTableColumns } from "../tableColumns";
import { inscriptions_api_uri } from "../values";
import { Row } from "react-bootstrap";

const SwimmerSearch = () => {
  const { championshipId, swimmerNameId } = useParams<{
    championshipId: string;
    swimmerNameId: string;
  }>();
  const championship = championshipId ? championshipId : "";
  const [swimmerName, setSwimmerName] = useState(
    swimmerNameId ? swimmerNameId : ""
  );
  const getSearchFilterFromFromData = () => {
    if (swimmerName === "") {
      return "";
    }
    const participant_filter = `participant_name=${swimmerName}`;
    return `${inscriptions_api_uri}?championship_name=${encodeURI(
      championship
    )}&${participant_filter}&results_limit=100`;
  };
  const [searchFilter, setSearchFilter] = useState(
    getSearchFilterFromFromData()
  );
  const handleFormChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSwimmerName(event.target.value);
  };
  const history = useHistory();
  const handleSubmit = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    const newPath =
      "/" + ["swimmersearch", championship, swimmerName].join("/");
    history.push(newPath);
    setSearchFilter(getSearchFilterFromFromData());
  };
  const location = useLocation();
  useEffect(() => {
    setSearchFilter(getSearchFilterFromFromData());
  }, [location]);
  const searchButtonStyle = {
    height: "auto",
    marginTop: "auto",
    marginBottom: "10px",
  };
  return (
    <>
      <Row className="form-row align-items-center">
        <div className="col-auto">
          <div className="form-group form-row">
            <input
              type="text"
              name="participant_name"
              placeholder="nombre"
              value={swimmerName}
              onChange={(e) => handleFormChange(e)}
              className="form-control"
            />
          </div>
        </div>
        <div className="col-auto" style={searchButtonStyle}>
          <button
            className="align-self-end btn btn-lg btn-block btn-primary"
            onClick={handleSubmit}
          >
            Buscar
          </button>
        </div>
      </Row>
      {searchFilter === "" ? (
        <></>
      ) : (
        <CompetyTableController
          columnsDefinition={swimmerSearchTableColumns}
          searchFilter={searchFilter}
        />
      )}
    </>
  );
};

export default SwimmerSearch;
