import React, { useCallback, useEffect, useState } from "react";
import CompetyTable from "./CompetyTable";
import SpyTable from "./SpyTable";
import { TableColumnType } from "../tableColumns";
import { SwimmerForTable } from "../values";
import { Row } from "@tanstack/react-table";

type CompetyTableControllerProps = {
  columnsDefinition: TableColumnType;
  searchFilter: string;
};

const CompetyTableController = ({
  columnsDefinition,
  searchFilter,
}: CompetyTableControllerProps) => {
  const [data, setSwimmers] = useState([]);

  useEffect(() => {
    (async () => {
      fetch(searchFilter)
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else {
            throw new Error(res.statusText);
          }
        })
        .then((res) => setSwimmers(res))
        .catch((err) => console.log("Error: ", err));
    })();
  }, [searchFilter]);
  const renderSpyComponent = useCallback(
    (row: Row<SwimmerForTable>) => (
      <>
        {row.original.participant.leverade ? (
          <SpyTable
            name={row.original.participant.leverade.name}
            surname={row.original.participant.leverade.surname}
            born_year={row.original.participant.born_year}
          />
        ) : null}
      </>
    ),
    []
  );
  return (
    <CompetyTable
      data={data}
      columns={columnsDefinition}
      renderRowSubComponent={renderSpyComponent}
    />
  );
};

export default CompetyTableController;
