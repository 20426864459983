import React, { useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { distances, inscriptions_api_uri, range, year } from "../values";
import { swimmerSearchTableColumns } from "../tableColumns";
import CompetyTableController from "./CompetyTableController";
import { styles } from "../models/Style";
import { genders } from "../models/Gender";
import { Button, Col, Form, Row } from "react-bootstrap";

type FormDataType = {
  club: string;
  event_distance: number;
  event_style: string;
  event_gender: string;
  participant_born_year_gte: number;
  participant_born_year_lt: number;
};

type FormDataTempType = {
  club: string;
  event_distance: string;
  event_style: string;
  event_gender: string;
  participant_born_year_gte: string;
  participant_born_year_lt: string;
};

const FormDataToTemp = (data: FormDataType) => {
  const temp: FormDataTempType = {
    club: data.club,
    event_distance: data.event_distance.toString(),
    event_style: data.event_style,
    event_gender: data.event_gender,
    participant_born_year_gte: data.participant_born_year_gte.toString(),
    participant_born_year_lt: data.participant_born_year_lt.toString(),
  };
  return temp;
};

const FormDataTempToData = (data: FormDataTempType) => {
  const temp: FormDataType = {
    club: data.club,
    event_distance: parseInt(data.event_distance),
    event_style: data.event_style,
    event_gender: data.event_gender,
    participant_born_year_gte: parseInt(
      data.participant_born_year_gte.toString()
    ),
    participant_born_year_lt: parseInt(
      data.participant_born_year_lt.toString()
    ),
  };
  return temp;
};

const AdvancedSearch = () => {
  const {
    championshipId,
    clubId,
    distanceId,
    styleId,
    genderId,
    bornFromId,
    bornUntilId,
  } = useParams<{
    championshipId: string;
    clubId: string;
    distanceId: string;
    styleId: string;
    genderId: string;
    bornFromId: string;
    bornUntilId: string;
  }>();
  const urlIncludesData =
    championshipId &&
    clubId &&
    distanceId &&
    styleId &&
    genderId &&
    bornFromId &&
    bornUntilId;
  const championship = championshipId ? championshipId : "";
  const [formData, setFormData] = useState<FormDataType>({
    club: clubId ? clubId : "",
    event_distance: distanceId ? parseInt(distanceId) : 0,
    event_style: styleId ? styleId.toUpperCase() : "0",
    event_gender: genderId ? genderId.toUpperCase() : "0",
    participant_born_year_gte: bornFromId ? parseInt(bornFromId) : 0,
    participant_born_year_lt: bornUntilId ? parseInt(bornUntilId) : 0,
  });
  const getSearchFilterFromFromData = () => {
    if (!urlIncludesData) {
      return "";
    }

    if (formData.club.length < 3) {
      return "";
    }
    let newSearchFilter = `${inscriptions_api_uri}?championship_name=${encodeURI(
      championship
    )}&club=${encodeURI(
      formData.club
    )}&results_limit=100&event_type=individual`;
    if (formData.event_distance !== 0) {
      newSearchFilter += `&event_distance=${formData.event_distance}`;
    }
    if (formData.event_style !== "0") {
      newSearchFilter += `&event_style=${formData.event_style}`;
    }
    if (formData.event_gender !== "0") {
      newSearchFilter += `&event_gender=${formData.event_gender}`;
    }
    if (formData.participant_born_year_gte !== 0) {
      newSearchFilter += `&participant_born_year_gte=${formData.participant_born_year_gte}`;
    }
    if (formData.participant_born_year_lt !== 0) {
      newSearchFilter += `&participant_born_year_lt=${formData.participant_born_year_lt}`;
    }
    return newSearchFilter;
  };
  const [searchFilter, setSearchFilter] = useState<string>(
    getSearchFilterFromFromData()
  );
  const handleFormChange = (
    name: keyof FormDataType,
    event: React.BaseSyntheticEvent
  ) => {
    const change = FormDataToTemp(formData);
    change[name] = event.target.value;
    setFormData(FormDataTempToData(change));
  };
  const history = useHistory();
  const handleSubmit = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    const newPath =
      "/" +
      [
        "advancedsearch",
        championship,
        formData.club,
        formData.event_distance,
        formData.event_style,
        formData.event_gender,
        formData.participant_born_year_gte,
        formData.participant_born_year_lt,
      ].join("/");
    history.push(newPath);
    setSearchFilter(getSearchFilterFromFromData());
  };
  const location = useLocation();
  useEffect(() => {
    setSearchFilter(getSearchFilterFromFromData());
  }, [location]);
  const bottomStyle = {
    height: "auto",
    marginTop: "auto",
    marginBottom: "15px",
  };
  return (
    <>
      <Row className="align-items-center">
        <Form.Group as={Col} md="auto" style={bottomStyle}>
          <Form.Control
            type="text"
            name="club"
            placeholder="Club"
            className="form-control"
            value={formData.club}
            onChange={(e) => handleFormChange("club", e)}
          />
        </Form.Group>
        <Form.Group as={Col} md="auto" style={bottomStyle}>
          <Form.Label>Distancia:</Form.Label>
          <Form.Select
            aria-label="Distancia"
            id="event_distance"
            name="event_distance"
            className="form-control"
            value={formData.event_distance}
            onChange={(e) => handleFormChange("event_distance", e)}
          >
            <option value="0">Todas</option>
            {distances.map((distance) => (
              <option key={distance} value={distance}>
                {distance}m
              </option>
            ))}
          </Form.Select>
        </Form.Group>
        <Form.Group as={Col} md="auto" style={bottomStyle}>
          <Form.Label>Estilo:</Form.Label>
          <Form.Select
            id="event_style"
            name="event_style"
            className="form-control"
            value={formData.event_style}
            onChange={(e) => handleFormChange("event_style", e)}
          >
            <option value="0">Todos</option>
            {styles.map((style) => (
              <option key={style} value={style.toUpperCase()}>
                {style}
              </option>
            ))}
          </Form.Select>
        </Form.Group>
        <Form.Group as={Col} md="auto" style={bottomStyle}>
          <Form.Label>Genero:</Form.Label>
          <Form.Select
            id="event_gender"
            name="event_gender"
            className="form-control"
            value={formData.event_gender}
            onChange={(e) => handleFormChange("event_gender", e)}
          >
            <option value="0">Todos</option>
            {genders.map((gender) => (
              <option key={gender} value={gender.toUpperCase()}>
                {gender}
              </option>
            ))}
          </Form.Select>
        </Form.Group>
        <Col md="auto">
          <Row>
            <Form.Group as={Col} md="auto" style={bottomStyle}>
              <Form.Label>Nacidos desde:</Form.Label>
              <Form.Select
                id="participant_born_year_gte"
                name="participant_born_year_gte"
                className="form-control"
                value={formData.participant_born_year_gte}
                onChange={(e) =>
                  handleFormChange("participant_born_year_gte", e)
                }
              >
                <option value="0">Todos</option>
                {range(year - 105, year - 19, 1).map((year) => (
                  <option key={"gte" + year} value={year}>
                    {year}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
            <Form.Group as={Col} md="auto" style={bottomStyle}>
              <Form.Label>Nacidos hasta (no se incluye):</Form.Label>
              <Form.Select
                id="participant_born_year_lt"
                name="participant_born_year_lt"
                className="form-control"
                value={formData.participant_born_year_lt}
                onChange={(e) =>
                  handleFormChange("participant_born_year_lt", e)
                }
              >
                <option value="0">Todos</option>
                {range(year - 105, year - 19, 1).map((year) => (
                  <option key={"lt" + year} value={year}>
                    {year}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          </Row>
        </Col>
        <Col>
          <Button
            style={bottomStyle}
            className="align-self-end btn btn-lg btn-block btn-primary"
            onClick={handleSubmit}
          >
            Buscar
          </Button>
        </Col>
      </Row>
      <div className="col-auto" style={bottomStyle}></div>
      {searchFilter === "" ? (
        <></>
      ) : (
        <CompetyTableController
          columnsDefinition={swimmerSearchTableColumns}
          searchFilter={searchFilter}
        />
      )}
    </>
  );
};

export default AdvancedSearch;
