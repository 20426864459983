import React from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { RelayHelperController } from "../TeamsRelaysHelper";

export const RelayHelperBackup = ({
  relayHelperController,
  reload,
}: {
  relayHelperController: RelayHelperController;
  reload: () => void;
}) => {
  const readJson = (file: Blob) => {
    const promise = new Promise<string>((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsText(file);
      fileReader.onload = (e) => {
        if (null == e || null == e.target) {
          reject("target is null");
          return;
        }
        const dataString = e.target.result;
        if (null === dataString) {
          reject("Unable to load file");
          return;
        }
        resolve(dataString as string);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
    promise.then((d) => {
      relayHelperController.loadBackup(d);
      reload();
    });
  };

  const saveFile = async () => {
    const blob = new Blob([relayHelperController.createBackup()], {
      type: "application/json",
    });
    const a = document.createElement("a");
    a.download = "relayHelper.json";
    a.href = URL.createObjectURL(blob);
    a.addEventListener("click", () => {
      setTimeout(() => URL.revokeObjectURL(a.href), 30 * 1000);
    });
    a.click();
  };

  return (
    <Container fluid="md">
      <Row>
        <Col>Cargar backup</Col>
        <Col>
          <input
            type="file"
            onChange={(e) => {
              if (null == e || null == e.target || null == e.target.files) {
                return;
              }
              const file = e.target.files[0];
              readJson(file);
            }}
          />
        </Col>
      </Row>
      <Row>
        <div className={"col-auto"}>
          <Button variant="secondary" onClick={saveFile}>
            Crear backup
          </Button>
        </div>
      </Row>
    </Container>
  );
};
