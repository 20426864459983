import React, { useState } from "react";
import { gendersTarray, genderT, genderToRepr } from "../models/Gender";
import { relayCategories, relayCategoriesT } from "../models/Category";
import { styleT } from "../models/Style";

export type FormDataType = {
  distance: 50 | 100;
  style: "free" | "medley";
  gender: genderT;
  category: relayCategoriesT;
};

const RelayForm = ({
  handleSubmit,
}: {
  handleSubmit: (formData: FormDataType) => void;
}) => {
  const [formData, setFormData] = useState<FormDataType>({
    distance: 50,
    style: "free",
    gender: "male",
    category: 80,
  });
  const relayStyleToRepr = (style: styleT): string => {
    switch (style) {
      case "free":
        return "Libre";
      case "medley":
        return "Estilos";
    }
    return "";
  };
  const relayStyles: styleT[] = ["free", "medley"];
  return (
    <div>
      <div className="form-row align-items-center">
        <div className="form-group col-auto">
          <label htmlFor="distancia">Distancia:</label>
          <select
            id="distancia"
            className="form-control"
            name="distance"
            value={formData.distance}
            onChange={(e) => {
              const distance = parseInt(e.target.value);
              const newFormData = { ...formData };
              newFormData.distance =
                distance === 50 || distance === 100 ? distance : 50;
              setFormData(newFormData);
            }}
          >
            {[50, 100].map((distance) => (
              <option key={distance} value={distance}>
                4x{distance}m
              </option>
            ))}
          </select>
        </div>
        <div className="form-group col-auto">
          <label htmlFor="estilo">Estilo:</label>
          <select
            id="estilo"
            className="form-control"
            name="style"
            value={formData.style}
            onChange={(e) => {
              const newFormData = { ...formData };
              newFormData.style =
                e.target.value === "free" || e.target.value === "medley"
                  ? e.target.value
                  : "free";
              setFormData(newFormData);
            }}
          >
            {relayStyles.map((style: styleT) => (
              <option key={style} value={style}>
                {relayStyleToRepr(style)}
              </option>
            ))}
          </select>
        </div>
        <div className="form-group col-auto">
          <label htmlFor="genero">Genero:</label>
          <select
            id="genero"
            className="form-control"
            name="gender"
            value={formData.gender}
            onChange={(e) => {
              const newFormData = { ...formData };
              newFormData.gender = e.target.value as genderT;
              setFormData(newFormData);
            }}
          >
            {gendersTarray.map((gender) => (
              <option key={gender} value={gender}>
                {genderToRepr(gender)}
              </option>
            ))}
          </select>
        </div>
        <div className="form-group col-auto">
          <label htmlFor="categoria">Categoria:</label>
          <select
            id="categoria"
            className="form-control"
            name="category"
            value={formData.category}
            onChange={(e) => {
              const newFormData = { ...formData };
              newFormData.category = parseInt(
                e.target.value
              ) as relayCategoriesT;
              setFormData(newFormData);
            }}
          >
            {relayCategories.map((cat) => (
              <option key={cat} value={cat}>
                {cat}
              </option>
            ))}
          </select>
        </div>
        <div className="col-auto">
          <button
            id="add"
            className="btn btn-primary"
            onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
              event.preventDefault();
              handleSubmit(formData);
            }}
          >
            Añadir
          </button>
        </div>
      </div>
    </div>
  );
};

export default RelayForm;
