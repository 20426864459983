import React, { useState } from "react";
import { Redirect, Route, Router, Switch } from "react-router-dom";
import { createBrowserHistory } from "history";
import "bootstrap/dist/css/bootstrap.css";
import "./App.css";
import NavBar from "./components/NavBar";
import AppFooter from "./components/AppFooter";
import EventSearch from "./components/EventSearch";
import SwimmerSearch from "./components/SwimmerSearch";
import AdvancedSearch from "./components/AdvancedSearch";
import BestTimesSearch from "./components/BestTimesSearch";
import { distances } from "./values";
import { styles } from "./models/Style";
import { genders } from "./models/Gender";
import { categories, relayCategories } from "./models/Category";
import { CookieConsent } from "react-cookie-consent";
import { RelayHelper } from "./components/RelayHelper";

const history = createBrowserHistory();

const distancesRegex = distances.join("|");
const stylesRegex = styles.join("|");
const genderRegex = genders.join("|");
const uniqueReducer = (unique: Array<number>, item: number) =>
  unique.includes(item) ? unique : [...unique, item];
const categoriesRegex = categories
  .concat(relayCategories)
  .reduce(uniqueReducer, [])
  .join("|");
const eventNavPath = `/eventsearch/:championshipId/:distanceId(${distancesRegex})/:styleId(${stylesRegex})/:genderId(${genderRegex})/:categoryId(${categoriesRegex})/:isRelayId(0|1)`;
const advancedNavPath = `/advancedsearch/:championshipId/:clubId/:distanceId(0|${distancesRegex})/:styleId(0|${stylesRegex})/:genderId(0|${genderRegex})/:bornFromId(\\d+)/:bornUntilId(\\d+)`;

function App() {
  const [championship, setChampionship] = useState("");
  return (
    <Router history={history}>
      <NavBar setChampionship={setChampionship} />
      <div
        className="container"
        style={{ backgroundColor: "var(--bs-body-bg)" }}
      >
        <Switch>
          <Route exact path={eventNavPath}>
            <h2>{championship}</h2>
            <EventSearch />
          </Route>
          <Route exact path="/eventsearch/:championshipId">
            <h2>{championship}</h2>
            <EventSearch />
          </Route>
          <Route path="/swimmersearch/:championshipId/:swimmerNameId">
            <h2>{championship}</h2>
            <SwimmerSearch />
          </Route>
          <Route exact path="/swimmersearch/:championshipId">
            <h2>{championship}</h2>
            <SwimmerSearch />
          </Route>
          <Route path={advancedNavPath}>
            <h2>{championship}</h2>
            <AdvancedSearch />
          </Route>
          <Route path="/advancedsearch/:championshipId">
            <h2>{championship}</h2>
            <AdvancedSearch />
          </Route>
          <Route path="/swimmertimes/:nameId/:surnameId/:bornYearId(\d+)">
            <BestTimesSearch />
          </Route>
          <Route path="/swimmertimes">
            <BestTimesSearch />
          </Route>
          <Route path="/relayhelper/:championshipId">
            <h2>{championship}</h2>
            <RelayHelper />
          </Route>
          <Route path="/">
            {championship ? (
              <Redirect to={"/eventsearch/" + championship} />
            ) : (
              <div>Home</div>
            )}
          </Route>
        </Switch>
      </div>
      <CookieConsent buttonText="De acuerdo">
        Esta página usa cookies para mejorar la experiencia de usuario.
      </CookieConsent>
      <AppFooter />
    </Router>
  );
}

export default App;
