import { ParticipantTimes } from "./participantTimes";
import { LeveradeParticipant } from "./leverade";
import { EventResult } from "./EventResult";
import { EventResultController } from "./EventResultController";
import { distancesT, seassonsMap, stylesT } from "./values";
import moment from "moment";

export class LeveradeWithCompetispyBackupEventResultController
  implements EventResultController
{
  private competispyEventResultController: ParticipantTimes;
  private leveradeEventResultController: LeveradeParticipant;

  constructor(
    public name: string,
    public surname: string,
    public birth_year: number,
    private getAccessToken: () => Promise<string>
  ) {
    this.competispyEventResultController = new ParticipantTimes(
      name,
      surname,
      birth_year,
      getAccessToken
    );
    this.leveradeEventResultController = new LeveradeParticipant(
      name,
      surname,
      birth_year
    );
  }

  get_best_event_result(
    distance: distancesT,
    style: stylesT,
    pool: 25 | 50,
    chronometer_type: "E" | "M" | null,
    season: keyof seassonsMap<void>,
    setEventResult: (eventResult: EventResult | undefined) => void
  ) {
    return this.get_event_results(
      distance,
      style,
      pool,
      setEventResult,
      chronometer_type,
      1,
      season
    );
  }

  get_event_results(
    distance: distancesT,
    style: stylesT,
    pool: 25 | 50,
    setEventResult: (eventResult: EventResult | undefined) => void,
    chronometer_type: "E" | "M" | null = null,
    result_size = 10,
    season: keyof seassonsMap<void> = "all",
    sort_mode = "time",
    sort_direction: 0 | 1 = 1,
    results_limit = 10,
    start_index = 0
  ) {
    const setEventResultWrapper = (
      eventResultFromCompetispy: EventResult | undefined
    ): void => {
      if (
        eventResultFromCompetispy &&
        this.isEventResultRecent(eventResultFromCompetispy)
      ) {
        setEventResult(eventResultFromCompetispy);
        return;
      }
      const saveEventResultWrapper = (
        eventResultFromLeverade: EventResult | undefined
      ): void => {
        if (eventResultFromLeverade) {
          this.competispyEventResultController.save_event_result(
            eventResultFromLeverade
          );
          setEventResult(eventResultFromLeverade);
          return;
        }
        setEventResult(eventResultFromCompetispy);
      };
      this.leveradeEventResultController.get_event_results(
        distance,
        style,
        pool,
        saveEventResultWrapper,
        chronometer_type,
        result_size,
        season,
        sort_mode,
        sort_direction,
        results_limit,
        start_index
      );
    };
    this.competispyEventResultController.get_event_results(
      distance,
      style,
      pool,
      setEventResultWrapper,
      chronometer_type,
      result_size,
      season,
      sort_mode,
      sort_direction,
      results_limit,
      start_index
    );
  }

  get_unique_id(): string {
    return this.leveradeEventResultController.get_unique_id();
  }

  private isEventResultRecent(eventResult: EventResult): boolean {
    return moment(eventResult.when, "DD/MM/YYYY").add(1, "weeks").isAfter();
  }
}
