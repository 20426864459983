import React, { ReactNode } from "react";
import {
  Column,
  Row,
  Table,
  ExpandedState,
  useReactTable,
  getCoreRowModel,
  getPaginationRowModel,
  getFilteredRowModel,
  getExpandedRowModel,
  ColumnDef,
  flexRender,
} from "@tanstack/react-table";
import { SwimmerForTable } from "../values";

type CompetyTableProps = {
  data: SwimmerForTable[];
  columns: ColumnDef<SwimmerForTable>[];
  renderRowSubComponent: (row: Row<SwimmerForTable>) => ReactNode;
};

const CompetyTable = ({
  data,
  columns,
  renderRowSubComponent,
}: CompetyTableProps) => {
  const [expanded, setExpanded] = React.useState<ExpandedState>({});
  const table = useReactTable({
    data,
    columns,
    state: {
      expanded,
    },
    onExpandedChange: setExpanded,
    /*
    getSubRows: (row, index) => {
      console.log(index);
      return row.subRows},
    */
    getCoreRowModel: getCoreRowModel(),
    // getPaginationRowModel: getPaginationRowModel(),
    // getFilteredRowModel: getFilteredRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    manualExpanding: true,
    enableExpanding: true,
  });

  return (
    <div style={{ overflowX: "auto" }}>
      <table className="table">
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <th key={header.id} colSpan={header.colSpan}>
                    <div>
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                    </div>
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => {
            return (
              <>
                <tr key={row.id}>
                  {row.getVisibleCells().map((cell) => {
                    return (
                      <td key={cell.id}>
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </td>
                    );
                  })}
                </tr>
                {row.getIsExpanded() ? (
                  <tr key={"sub_" + row.id}>
                    <td
                      key={"sub_col_" + row.id}
                      colSpan={row.getVisibleCells().length}
                    >
                      {renderRowSubComponent(row)}
                    </td>
                  </tr>
                ) : null}
              </>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default CompetyTable;
