import { distancesT, seassonsMap, stylesT, times_api_uri } from "./values";
import { cachedFetch } from "./catched_fetch";
import { EventResult } from "./EventResult";
import { EventResultController } from "./EventResultController";

const myUrlSearchParams = (init: Record<string, string | number | boolean>) =>
  new URLSearchParams(init as Record<string, string>);

export class ParticipantTimes implements EventResultController {
  private disableCache = false;
  constructor(
    public name: string,
    public surname: string,
    public birth_year: number,
    private getAccessToken: () => Promise<string>
  ) {}

  get_best_event_result(
    distance: distancesT,
    style: stylesT,
    pool: 25 | 50,
    chronometer_type: "E" | "M" | null,
    season: keyof seassonsMap<void>,
    setEventResult: (eventResult: EventResult | undefined) => void
  ) {
    return this.get_event_results(
      distance,
      style,
      pool,
      setEventResult,
      chronometer_type,
      1,
      season
    );
  }

  get_event_results(
    distance: distancesT,
    style: stylesT,
    pool: 25 | 50,
    setEventResult: (eventResult: EventResult | undefined) => void,
    chronometer_type: "E" | "M" | null = null,
    result_size = 10,
    season: keyof seassonsMap<void> = "all",
    sort_mode = "time",
    sort_direction: 0 | 1 = 1,
    results_limit = 10,
    start_index = 0
  ) {
    const params = myUrlSearchParams({
      event_distance: distance,
      event_style: style,
      event_pool_length: pool,
      participant_born_year: this.birth_year,
      participant_name: this.name,
      participant_surname: this.surname,
      season: season,
      sort_mode: sort_mode,
      sort_direction: sort_direction,
      results_limit: results_limit,
      start_index: start_index,
    });

    if (chronometer_type) {
      params.set("event_chronometer_type", chronometer_type);
    }

    const query = times_api_uri + "?" + params;
    const cacheExpirySeconds = this.getCacheExpirationTime();
    this.getAccessToken().then((token) => {
      cachedFetch(cacheExpirySeconds, query, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else {
            return undefined;
          }
        })
        .then((res: EventResult[] | undefined) => {
          if (res && res.length > 0) {
            setEventResult(res[0]);
          } else {
            setEventResult(undefined);
          }
        })
        .catch((err) => console.log("Error: ", err));
    });
  }

  private getCacheExpirationTime(): number {
    if (this.disableCache) {
      this.disableCache = false;
      return 0;
    }
    return 24 * 60 * 60; // One day in seconds
  }

  get_unique_id(): string {
    return this.name + this.surname + this.birth_year;
  }

  async save_event_result(eventResult: EventResult) {
    const token = await this.getAccessToken();
    await fetch(times_api_uri, {
      method: "POST",
      body: JSON.stringify(eventResult),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
  }
}
