import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";
import SpyTable from "./SpyTable";

const BestTimesSearch = () => {
  const { nameId, surnameId, bornYearId } = useParams<{
    nameId: string;
    surnameId: string;
    bornYearId: string;
  }>();
  const [participantData, setParticipantData] = useState({
    participant: {
      leverade: {
        name: nameId ? nameId : "",
        surname: surnameId ? surnameId : "",
      },
      born_year: bornYearId ? parseInt(bornYearId) : 0,
    },
  });
  const [formData, setFormData] = useState({
    participant_name: nameId ? nameId : "",
    participant_surname: surnameId ? surnameId : "",
    participant_born_year: bornYearId ? bornYearId : "",
  });
  const handleFormChange = (
    name: keyof typeof formData,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const change = { ...formData };
    change[name] = event.target.validity.valid
      ? event.target.value
      : change[name];
    setFormData(change);
  };
  const history = useHistory();
  const handleSubmit = (event: React.MouseEvent<HTMLButtonElement>) => {
    const change = { ...participantData };
    change.participant.leverade.name = formData.participant_name;
    change.participant.leverade.surname = formData.participant_surname;
    change.participant.born_year = parseInt(formData.participant_born_year, 10);
    event.preventDefault();
    const newPath =
      "/" +
      [
        "swimmertimes",
        formData.participant_name,
        formData.participant_surname,
        formData.participant_born_year,
      ].join("/");
    history.push(newPath);
    setParticipantData(change);
  };
  const searchButtonStyle = {
    height: "auto",
    marginTop: "auto",
    marginBottom: "10px",
  };
  return (
    <>
      <Row className="align-items-center">
        <Col md="auto">
          <input
            type="text"
            name="participant_name"
            placeholder="nombre"
            value={formData.participant_name}
            onChange={(e) => handleFormChange("participant_name", e)}
            className="form-control"
          />
        </Col>
        <Col md="auto">
          <input
            type="text"
            name="participant_surname"
            placeholder="apellidos"
            value={formData.participant_surname}
            onChange={(e) => handleFormChange("participant_surname", e)}
            className="form-control"
          />
        </Col>
        <Col md="auto">
          <input
            type="tel"
            name="participant_born_year"
            placeholder="año nacimiento"
            pattern="\d?\d?\d?\d?"
            value={formData.participant_born_year}
            onChange={(e) => handleFormChange("participant_born_year", e)}
            className="form-control"
          />
        </Col>
        <Col md="auto">
          <button
            className="align-self-end btn btn-lg btn-block btn-primary"
            style={searchButtonStyle}
            onClick={handleSubmit}
          >
            Buscar
          </button>
        </Col>
      </Row>
      {participantData.participant.leverade.name === "" &&
      participantData.participant.leverade.surname === "" ? (
        <></>
      ) : (
        <SpyTable
          name={participantData.participant.leverade.name}
          surname={participantData.participant.leverade.surname}
          born_year={participantData.participant.born_year}
        />
      )}
    </>
  );
};

export default BestTimesSearch;
