type taskT = () => Promise<void>;

class Executor {
  private tasks: taskT[];
  private readonly setProgress: (progress: number) => void;
  private readonly initialTaskLength: number;

  constructor(tasks: taskT[], setProgress: (progress: number) => void) {
    this.tasks = tasks;
    this.setProgress = setProgress;
    this.initialTaskLength = this.tasks.length;
  }

  async execute() {
    while (this.tasks.length > 0) {
      const task = this.tasks.shift();
      if (task) {
        await task();
        const progress =
          (100 * (this.initialTaskLength - this.tasks.length)) /
          this.initialTaskLength;
        this.setProgress(progress);
      }
    }
  }
}

export class ParallelTaskExecutor {
  private readonly tasks: taskT[];
  private readonly nParallelWorkers: number;
  private readonly setProgress: (progress: number) => void;

  constructor(
    nParallelWorkers: number,
    setProgress: (progress: number) => void
  ) {
    this.tasks = [];
    this.nParallelWorkers = nParallelWorkers;
    this.setProgress = setProgress;
  }

  addTask(task: taskT) {
    this.tasks.push(task);
  }

  addTasks(tasks: taskT[]) {
    for (const task of tasks) {
      this.tasks.push(task);
    }
  }

  async execute() {
    this.setProgress(0);
    const executors: Executor[] = [];
    for (let i = 0; i < this.nParallelWorkers; i++) {
      executors.push(new Executor(this.tasks, this.setProgress));
    }
    await Promise.all(
      executors.map(async (executor) => await executor.execute())
    );
  }
}
