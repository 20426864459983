import EventRenderer, {
  EventRendererValueTypes,
} from "./components/tablecellrenderers/EventRenderer";
import InscriptionTimeRenderer from "./components/tablecellrenderers/InscriptionTimeRenderer";
import SpyRenderer, {
  SpyRendererValuesType,
} from "./components/tablecellrenderers/SpyRenderer";
import React from "react";
import { ColumnDef } from "@tanstack/react-table";
import { PoolType, SwimmerForTable } from "./values";

const spyColum: ColumnDef<SwimmerForTable> = {
  accessorKey: "participant",
  header: "Spy",
  cell: ({ row, getValue }) => (
    <SpyRenderer row={row} values={getValue<SpyRendererValuesType>()} />
  ),
  footer: (props) => props.column.id,
};

const clubColumn: ColumnDef<SwimmerForTable> = {
  accessorKey: "club",
  header: "Club",
  footer: (props) => props.column.id,
};

const initialPositionColumn: ColumnDef<SwimmerForTable> = {
  accessorKey: "initial_position",
  header: "Posicion",
  footer: (props) => props.column.id,
};

const inscriptionTimeColumn: ColumnDef<SwimmerForTable> = {
  accessorKey: "inscription_time.time",
  header: "Tiempo",
  cell: ({ getValue }) => <InscriptionTimeRenderer time={getValue<number>()} />,
  id: "time",
  footer: (props) => props.column.id,
};

const inscriptionPoolColumn: ColumnDef<SwimmerForTable> = {
  accessorKey: "inscription_time",
  header: "Piscina",
  cell: ({ getValue }) => (
    <>
      {getValue<PoolType>().pool_length}-{getValue<PoolType>().chronometer_type}
    </>
  ),
  id: "pool",
  footer: (props) => props.column.id,
};

const heatColumn: ColumnDef<SwimmerForTable> = {
  accessorKey: "heat",
  header: "Serie",
  footer: (props) => props.column.id,
};

const laneColumn: ColumnDef<SwimmerForTable> = {
  accessorKey: "lane",
  header: "Calle",
  footer: (props) => props.column.id,
};

const bornYearColumn: ColumnDef<SwimmerForTable> = {
  accessorKey: "participant.born_year",
  header: "Nacimiento",
  footer: (props) => props.column.id,
};

const indexColumn: ColumnDef<SwimmerForTable> = {
  header: "#",
  id: "row",
  cell: ({ row }) => <div>{row.index + 1}</div>,
  footer: (props) => props.column.id,
};

export const swimmerSearchTableColumns: ColumnDef<SwimmerForTable>[] = [
  spyColum,
  {
    accessorKey: "participant.name",
    header: "Nombre",
    footer: (props) => props.column.id,
  },
  {
    accessorKey: "event",
    header: "Prueba",
    cell: ({ getValue }) => (
      <EventRenderer values={getValue<EventRendererValueTypes>()} />
    ),
    footer: (props) => props.column.id,
  },
  clubColumn,
  initialPositionColumn,
  inscriptionTimeColumn,
  inscriptionPoolColumn,
  heatColumn,
  laneColumn,
  bornYearColumn,
];

export const eventSearchTableColumn: ColumnDef<SwimmerForTable>[] = [
  spyColum,
  indexColumn,
  {
    accessorKey: "participant.name",
    header: "Nombre",
    footer: (props) => props.column.id,
  },
  clubColumn,
  inscriptionTimeColumn,
  inscriptionPoolColumn,
  heatColumn,
  laneColumn,
  bornYearColumn,
];

export const eventRelaySearchColumns: ColumnDef<SwimmerForTable>[] = [
  indexColumn,
  clubColumn,
  inscriptionTimeColumn,
  inscriptionPoolColumn,
  heatColumn,
  laneColumn,
  {
    accessorKey: "event.category",
    header: "Categoria",
    footer: (props) => props.column.id,
  },
];

export type TableColumnType =
  | typeof swimmerSearchTableColumns
  | typeof eventSearchTableColumn
  | typeof eventRelaySearchColumns;
