export type genderT = "male" | "female" | "mix";
export const gendersTarray: genderT[] = ["male", "female", "mix"];
export const genders = ["Masculino", "Femenino", "Mixto"];
export const genderToRepr = (gender: genderT) => {
  switch (gender) {
    case "male":
      return "Masculino";
    case "female":
      return "Femenino";
    case "mix":
      return "Mixto";
  }
};
