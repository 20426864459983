import React, { useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { distances, inscriptions_api_uri } from "../values";
import {
  eventRelaySearchColumns,
  eventSearchTableColumn,
} from "../tableColumns";
import CompetyTableController from "./CompetyTableController";
import { relayDistances } from "../models/Relay";
import { categories, relayCategories } from "../models/Category";
import { styles } from "../models/Style";
import { genders } from "../models/Gender";
import { Col, Form, FormGroup, Row } from "react-bootstrap";

type EventSearchTProps = {
  searchFilter: string;
};

const EventSearchT = ({ searchFilter }: EventSearchTProps) => {
  const isRelay = searchFilter.includes("event_type=relay");
  return (
    <>
      {isRelay ? (
        <CompetyTableController
          columnsDefinition={eventRelaySearchColumns}
          searchFilter={searchFilter}
        />
      ) : (
        <CompetyTableController
          columnsDefinition={eventSearchTableColumn}
          searchFilter={searchFilter}
        />
      )}
    </>
  );
};

type FormDataType = {
  distance: number;
  style: string;
  gender: string;
  category: string;
  isRelay: boolean;
};

type FormDataTempType = {
  distance: string;
  style: string;
  gender: string;
  category: string;
  isRelay: string;
};

const FormDataToTemp = (data: FormDataType) => {
  const temp: FormDataTempType = {
    distance: data.distance.toString(),
    style: data.style,
    gender: data.gender,
    category: data.category,
    isRelay: data.isRelay ? "1" : "0",
  };
  return temp;
};

const FormDataTempToData = (data: FormDataTempType) => {
  const temp: FormDataType = {
    distance: parseInt(data.distance),
    style: data.style,
    gender: data.gender,
    category: data.category,
    isRelay: data.isRelay === "1",
  };
  return temp;
};

const EventSearch = () => {
  const {
    championshipId,
    distanceId,
    styleId,
    genderId,
    categoryId,
    isRelayId,
  } = useParams<{
    championshipId: string;
    distanceId: string;
    styleId: string;
    genderId: string;
    categoryId: string;
    isRelayId: string;
  }>();
  const championship = championshipId ? championshipId : "";
  const urlIncludesData =
    championshipId &&
    distanceId &&
    styleId &&
    genderId &&
    categoryId &&
    isRelayId;
  const [formData, setFormData] = useState<FormDataType>({
    distance: distanceId ? parseInt(distanceId) : 50,
    style: styleId ? styleId.toUpperCase() : "MARIPOSA",
    gender: genderId ? genderId.toUpperCase() : "MASCULINO",
    category: categoryId ? categoryId : "25",
    isRelay: isRelayId ? isRelayId === "1" : false,
  });
  const getSearchFilterFromFromData = () => {
    if (!urlIncludesData) {
      return "";
    }
    const event_type = formData.isRelay ? "relay" : "individual";
    const categoryPrefix = formData.isRelay ? "%2B" : "";
    return `${inscriptions_api_uri}?championship_name=${encodeURI(
      championship
    )}&event_distance=${formData.distance}&event_style=${
      formData.style
    }&event_gender=${formData.gender}&event_category=${
      categoryPrefix + formData.category
    }&event_type=${event_type}&results_limit=100`;
  };
  const [availableDistances, setAvailableDistances] = useState(
    formData.isRelay ? relayDistances : distances
  );
  const [availableCategories, setAvailableCategories] = useState(
    formData.isRelay ? relayCategories : categories
  );
  const [searchFilter, setSearchFilter] = useState(
    getSearchFilterFromFromData()
  );
  const handleIsRelayChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newIsRelay = event.target.checked;
    const change = { ...formData };
    change.isRelay = newIsRelay;
    setFormData(change);

    if (newIsRelay) {
      setAvailableDistances(relayDistances);
      setAvailableCategories(relayCategories);
    } else {
      setAvailableDistances(distances);
      setAvailableCategories(categories);
    }
  };
  const handleFormChange = (
    name: keyof FormDataType,
    event:
      | React.ChangeEvent<HTMLSelectElement>
      | React.ChangeEvent<HTMLInputElement>
  ) => {
    const change = FormDataToTemp(formData);
    change[name] = event.target.value;
    setFormData(FormDataTempToData(change));
  };
  const history = useHistory();
  const handleSubmit = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    const newPath =
      "/" +
      [
        "eventsearch",
        championship,
        formData.distance,
        formData.style,
        formData.gender,
        formData.category,
        formData.isRelay ? "1" : "0",
      ].join("/");
    history.push(newPath);
    setSearchFilter(getSearchFilterFromFromData());
  };
  const location = useLocation();
  useEffect(() => {
    setSearchFilter(getSearchFilterFromFromData());
  }, [location]);
  return (
    <div>
      <Row className="align-items-center">
        <Form.Group as={Col} md="auto">
          <Form.Check
            id="isRelay"
            checked={formData.isRelay}
            onChange={handleIsRelayChange}
            name="isRelay"
            type="checkbox"
            label="Buscar relevo"
          />
        </Form.Group>
        <Form.Group as={Col} md="auto">
          <Form.Label>Distancia:</Form.Label>
          <Form.Select
            id="distancia"
            className="form-control"
            name="distance"
            value={formData.distance}
            onChange={(e) => handleFormChange("distance", e)}
          >
            {availableDistances.map((distance) => (
              <option key={distance} value={distance}>
                {formData.isRelay ? "4x" : ""}
                {distance}m
              </option>
            ))}
          </Form.Select>
        </Form.Group>
        <Form.Group as={Col} md="auto">
          <Form.Label htmlFor="estilo">Estilo:</Form.Label>
          <Form.Select
            id="estilo"
            className="form-control"
            name="style"
            value={formData.style}
            onChange={(e) => handleFormChange("style", e)}
          >
            {styles.map((style) => (
              <option key={style} value={style.toUpperCase()}>
                {style}
              </option>
            ))}
          </Form.Select>
        </Form.Group>
        <Form.Group as={Col} md="auto">
          <Form.Label htmlFor="genero">Genero:</Form.Label>
          <Form.Select
            id="genero"
            className="form-control"
            name="gender"
            value={formData.gender}
            onChange={(e) => handleFormChange("gender", e)}
          >
            {genders.map((gender) => (
              <option key={gender} value={gender.toUpperCase()}>
                {gender}
              </option>
            ))}
          </Form.Select>
        </Form.Group>
        <Form.Group as={Col} md="auto">
          <Form.Label htmlFor="categoria">Categoria:</Form.Label>
          <Form.Select
            id="categoria"
            className="form-control"
            name="category"
            value={formData.category}
            onChange={(e) => handleFormChange("category", e)}
          >
            {availableCategories.map((cat) => (
              <option key={cat} value={cat}>
                {cat}
              </option>
            ))}
          </Form.Select>
        </Form.Group>
        <Form.Group as={Col} md="auto">
          <button
            id="buscar"
            className="btn btn-primary"
            onClick={handleSubmit}
          >
            Buscar
          </button>
        </Form.Group>
      </Row>
      {searchFilter === "" ? null : (
        <EventSearchT searchFilter={searchFilter} />
      )}
    </div>
  );
};

export default EventSearch;
