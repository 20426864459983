import React from "react";

const AppFooter = () => {
  return (
    <footer className="footer container" style={{ backgroundColor: "white" }}>
      <p className="text-muted">
        Designed by <a href="mailto:miguel.a.j82@gmail.com">@thar</a>
      </p>
    </footer>
  );
};

export default AppFooter;
