export const range = (start: number, stop: number, step: number) =>
  Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + i * step);

type GetNameFromList<U, T extends readonly U[]> = T[number];

export const distances = [50, 100, 200, 400, 800, 1500] as const;
const distancesTemp = Array<number>(...distances);
export type distancesT = GetNameFromList<number, typeof distances>;
export function isDistance(d: number): d is distancesT {
  return distancesTemp.includes(d);
}

export const styles = [
  "mariposa",
  "espalda",
  "braza",
  "libre",
  "estilos",
] as const;
const stylesTemp = Array<string>(...styles);
export type stylesT = GetNameFromList<string, typeof styles>;
export function isStype(s: string): s is stylesT {
  return stylesTemp.includes(s);
}

export type seassonsMap<T> = {
  "24/25": T;
  "23/24": T;
  "22/23": T;
  "21/22": T;
  "20/21": T;
  "19/20": T;
  "18/19": T;
  "17/18": T;
  "16/17": T;
  "15/16": T;
  "14/15": T;
  "13/14": T;
  "12/13": T;
  "11/12": T;
  all: T;
};

export function createInitialSeassonsMap<T>(defaultValue: T) {
  const temp: seassonsMap<T> = {
    "24/25": defaultValue,
    "23/24": defaultValue,
    "22/23": defaultValue,
    "21/22": defaultValue,
    "20/21": defaultValue,
    "19/20": defaultValue,
    "18/19": defaultValue,
    "17/18": defaultValue,
    "16/17": defaultValue,
    "15/16": defaultValue,
    "14/15": defaultValue,
    "13/14": defaultValue,
    "12/13": defaultValue,
    "11/12": defaultValue,
    all: defaultValue,
  };
  return temp;
}

export const getKeys = Object.keys as <T>(obj: T) => Array<keyof T>;

export const year = new Date().getFullYear();

export const inscriptions_api_uri = "/api/v1/inscriptions";
export const championships_api_uri = "/api/v1/championships";
export const times_api_uri = "/api/v1/times";

export type ParticipantType = {
  name: string;
  born_year: number;
  leverade?: {
    name: string;
    surname: string;
  };
};

export type PoolType = {
  pool_length: 25 | 50;
  chronometer_type: "M" | "E";
};

export type SwimmerForTable = {
  heat: number;
  lane: number;
  session: number;
  club: string;
  initial_position: number;
  championship: {
    name: string;
    date: string;
  };
  event: {
    event_number: number;
    distance: number;
    style: string;
    gender: string;
    category: string;
    type: string;
  };
  participant: ParticipantType & {
    license: string | null;
    inscription_time: PoolType & {
      time: number;
      date: string;
      location: string;
    };
  };
};
