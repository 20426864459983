import React from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { Auth0Provider } from "@auth0/auth0-react";
import { getConfig } from "./config";
import LogoImage from "./background_v3.jpg";

const sectionStyle = {
  backgroundImage: `url(${LogoImage})`,
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  backgroundAttachment: "fixed",
  backgroundPosition: "center",
  height: "100vh",
};

const config = getConfig();

const providerConfig = {
  domain: config.domain,
  clientId: config.clientId,
  ...(config.audience ? { audience: config.audience } : null),
  redirectUri: window.location.origin,
};

const container = document.getElementById("root");
if (container) {
  const root = createRoot(container);

  root.render(
    <Auth0Provider {...providerConfig}>
      <React.StrictMode>
        <div className="overflow-auto" style={sectionStyle}>
          <App />
        </div>
      </React.StrictMode>
    </Auth0Provider>
  );
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
