import React, { useEffect, useState } from "react";
import { Table as ReactTable, FormCheck } from "react-bootstrap";
import {
  TeamSummaryControllerEntry,
  VectorTeamSummaryController_SummaryEntry,
  SwimmerId,
  Event,
  TimesPool,
} from "../TeamsRelaysHelper";
import { RelaysSwimmerTableTimesRow } from "./RelaysSwimmerTableTimesRow";

import {
  ColumnDef,
  useReactTable,
  getCoreRowModel,
  flexRender,
  getExpandedRowModel,
} from "@tanstack/react-table";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMars, faVenus } from "@fortawesome/free-solid-svg-icons";

type RelaysSwimmerTableProps = {
  swimmers: VectorTeamSummaryController_SummaryEntry;
  toggleSwimmerAvailability: (swimmerId: SwimmerId, sessionId: string) => void;
  setTime: (
    swimmerId: SwimmerId,
    event: Event,
    pool: TimesPool,
    time: number
  ) => void;
};

export const RelaysSwimmerTable = ({
  swimmers,
  toggleSwimmerAvailability,
  setTime,
}: RelaysSwimmerTableProps) => {
  const getRealSwimmers = () => {
    const entries: TeamSummaryControllerEntry[] = [];
    for (let i = 0; i < swimmers.size(); i++) {
      const entry = swimmers.get(i);
      if (entry) {
        entries.push(entry);
      }
    }
    return entries;
  };

  const [realSwimmers, setRealSwimmers] = useState<
    TeamSummaryControllerEntry[]
  >(getRealSwimmers());

  useEffect(() => {
    setRealSwimmers(getRealSwimmers());
  }, [swimmers]);

  const getSessionAvailability = () => {
    const sessionIds: string[] = [];
    if (realSwimmers.length > 0) {
      const sessionIdKeys = realSwimmers[0].sessionAvailability.keys();
      for (let i = 0; i < sessionIdKeys.size(); i++) {
        const entry = sessionIdKeys.get(i);
        if (entry) {
          sessionIds.push(entry.toString());
        }
      }
    }
    return sessionIds;
  };

  const sessionIds = getSessionAvailability();

  /* eslint-disable react/prop-types */
  const columns = React.useMemo<ColumnDef<TeamSummaryControllerEntry>[]>(
    () => [
      {
        header: "",
        id: "times",
        cell: ({ row }) => (
          <span
            role={"button"}
            onClick={(_) => {
              row.toggleExpanded();
            }}
          >
            {row.getIsExpanded() ? "👇" : "👉"}{" "}
          </span>
        ),

        footer: (props) => props.column.id,
      },
      {
        header: "Nombre",
        accessorFn: (row) => row.swimmerId.name + " " + row.swimmerId.surname,
        footer: (props) => props.column.id,
      },
      {
        header: "Edad",
        accessorKey: "age",
        footer: (props) => props.column.id,
      },
      {
        header: "Año nacimiento",
        accessorKey: "swimmerId.bornYear",
        footer: (props) => props.column.id,
      },
      {
        header: "Genero",
        cell: ({ row }) =>
          row.original.swimmerId.gender.value === 0 ? (
            <FontAwesomeIcon icon={faMars} style={{ color: "#74C0FC" }} />
          ) : (
            <FontAwesomeIcon icon={faVenus} style={{ color: "#9141ac" }} />
          ),
        footer: (props) => props.column.id,
      },
      {
        header: "Sesión",
        footer: (props) => props.column.id,
        columns: sessionIds.map((sessionId) => {
          return {
            header: sessionId,
            cell: ({ row }) => (
              <FormCheck
                onClick={() => {
                  toggleSwimmerAvailability(row.original.swimmerId, sessionId);
                }}
                readOnly
                checked={row.original.sessionAvailability.get(sessionId)}
              ></FormCheck>
            ),
            footer: (props) => props.column.id,
          };
        }),
      },
    ],
    [sessionIds]
  );

  const table = useReactTable({
    data: realSwimmers,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    manualExpanding: true,
    enableExpanding: true,
  });

  return (
    <div style={{ overflowX: "auto" }}>
      <ReactTable striped>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <th key={header.id} colSpan={header.colSpan}>
                    {header.isPlaceholder ? null : (
                      <div>
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                      </div>
                    )}
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => {
            return (
              <>
                <tr key={row.id}>
                  {row.getVisibleCells().map((cell, index) => {
                    return (
                      <>
                        {index === 0 ? (
                          <th key={cell.id}>
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext()
                            )}
                          </th>
                        ) : (
                          <td key={cell.id}>
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext()
                            )}
                          </td>
                        )}
                      </>
                    );
                  })}
                </tr>
                {row.getIsExpanded() && (
                  <tr key={"sub_" + row.id}>
                    <td
                      key={"sub_col_" + row.id}
                      colSpan={row.getVisibleCells().length}
                    >
                      <RelaysSwimmerTableTimesRow
                        swimmer={row.original}
                        setTime={setTime}
                      ></RelaysSwimmerTableTimesRow>
                    </td>
                  </tr>
                )}
              </>
            );
          })}
        </tbody>
      </ReactTable>
    </div>
  );
};
