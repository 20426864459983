import React from "react";
import {
  AvailableSwimmerWrapper,
  RelaySetSwimmerController,
  Style as StyleWASM,
} from "../TeamsRelaysHelper";

type SwimmerSortedTimeTableProps = {
  header: string;
  controller: RelaySetSwimmerController;
  handleClose: () => void;
  year: number;
  getWasmStyleRepr: (style: StyleWASM) => string;
};

const SwimmerSortedTimeTable = ({
  header,
  controller,
  handleClose,
  year,
  getWasmStyleRepr,
}: SwimmerSortedTimeTableProps) => {
  const getSwimmers = () => {
    const entries = controller.getAvailableSwimmers();
    const swimmers: AvailableSwimmerWrapper[] = [];
    for (let i = 0; i < entries.size(); i++) {
      const swimmer = entries.get(i);
      if (swimmer) {
        swimmers.push(swimmer);
      }
    }
    return swimmers;
  };
  const availableSwimmers = getSwimmers();

  const timeToString = (t: number) => {
    const minutes = Math.floor(t / 60);
    t -= minutes * 60;
    return minutes.toFixed(0) + ":" + t.toFixed(2).padStart(5, "0");
  };

  return (
    <div>
      <h3>{header}</h3>
      <table className="display table">
        <thead>
          <tr>
            <th scope="col">Name</th>
            <th scope="col">Born year</th>
            <th scope="col">Estilo</th>
            <th scope="col">Time</th>
            <th scope="col">Edad</th>
          </tr>
        </thead>
        <tbody>
          {availableSwimmers.map((d, index) => (
            <tr
              key={index}
              onClick={() => {
                controller.setSwimmer(d.id);
                handleClose();
              }}
            >
              <th>{d.id.name.toString() + " " + d.id.surname.toString()}</th>
              <td>{d.id.bornYear}</td>
              <td>{getWasmStyleRepr(d.event.style)}</td>
              <td>{timeToString(d.time)}</td>
              <td>{year - d.id.bornYear}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default SwimmerSortedTimeTable;
