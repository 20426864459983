import React, { useState } from "react";
import {
  Table as ReactTable,
  Modal,
  Button,
  Form,
  Row,
  Col,
} from "react-bootstrap";
import {
  TeamSummaryControllerEntry,
  SwimmerId,
  EventPoolTime,
  Event,
  TimesPool,
} from "../TeamsRelaysHelper";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";

const timeToString = (t: number): string => {
  const minutes = Math.floor(t / 60);
  t -= minutes * 60;
  return minutes.toFixed(0) + ":" + t.toFixed(2).padStart(5, "0");
};

type TimeCellProps = {
  value: EventPoolTime;
  setTime: (event: Event, pool: TimesPool, time: number) => void;
};

const TimeCell = ({ value, setTime }: TimeCellProps) => {
  const getOriginalMinutes = () => {
    return Math.floor(value.time / 60);
  };
  const getOriginalSeconds = () => {
    return Math.floor(value.time) % 60;
  };
  const getOriginalDeciseconds = () => {
    return Math.round(100 * (value.time - Math.floor(value.time)));
  };

  const updateTime = () => {
    setTime(
      value.event,
      value.pool,
      minutes * 60 + seconds + deciseconds / 100
    );
  };

  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
  };
  const handleShow = () => setShow(true);
  const zeroPad = (value: number) => {
    return value.toString().padStart(2, "0");
  };

  const [deciseconds, setDeciseconds] = useState(getOriginalDeciseconds());

  const [seconds, setSeconds] = useState(getOriginalSeconds());
  const [minutes, setMinutes] = useState(getOriginalMinutes());

  const resetForm = () => {
    setMinutes(getOriginalMinutes());
    setSeconds(getOriginalSeconds());
    setDeciseconds(getOriginalDeciseconds());
  };

  return (
    <>
      {timeToString(value.time)}{" "}
      <FontAwesomeIcon icon={faEdit} onClick={handleShow} />
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Nuevo tiempo</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Row className="mb-3">
              <Form.Group as={Col} className="mb-3" controlId="minutes">
                <Form.Label>Minutos</Form.Label>
                <Form.Control
                  type="number"
                  placeholder=""
                  onChange={(event) => {
                    setMinutes(parseInt(event.target.value));
                  }}
                  defaultValue={minutes}
                />
              </Form.Group>
              <Form.Group as={Col} className="mb-3" controlId="seconds">
                <Form.Label>Segundos</Form.Label>
                <Form.Control
                  type="number"
                  placeholder=""
                  onChange={(event) => {
                    setSeconds(parseInt(event.target.value));
                  }}
                  defaultValue={seconds}
                  min={0}
                  max={59}
                />
              </Form.Group>
              <Form.Group as={Col} className="mb-3" controlId="deciseconds">
                <Form.Label>Décimas</Form.Label>
                <Form.Control
                  type="number"
                  placeholder=""
                  onChange={(event) => {
                    setDeciseconds(parseInt(event.target.value));
                  }}
                  defaultValue={zeroPad(deciseconds)}
                  min="0"
                  max="99"
                />
              </Form.Group>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              handleClose();
              updateTime();
            }}
          >
            Actualizar
          </Button>
          <Button
            variant="secondary"
            onClick={() => {
              resetForm();
              handleClose();
            }}
          >
            Cancelar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

type RelaysSwimmerTableTimesRowProps = {
  swimmer: TeamSummaryControllerEntry;
  setTime: (
    swimmerId: SwimmerId,
    event: Event,
    pool: TimesPool,
    time: number
  ) => void;
};

export const RelaysSwimmerTableTimesRow = ({
  swimmer,
  setTime,
}: RelaysSwimmerTableTimesRowProps) => {
  const values = [
    swimmer.fly50,
    swimmer.fly100,
    swimmer.back50,
    swimmer.back100,
    swimmer.breast50,
    swimmer.breast100,
    swimmer.free50,
    swimmer.free100,
  ];

  return (
    <div style={{ overflowX: "auto" }}>
      <ReactTable striped>
        <thead>
          <tr>
            <th colSpan={2}>Mariposa</th>
            <th colSpan={2}>Espalda</th>
            <th colSpan={2}>Braza</th>
            <th colSpan={2}>Crol</th>
          </tr>
          <tr>
            {[1, 2, 3, 4].map((key) => {
              return (
                <>
                  <th key={key.toString() + "_50"}>50</th>
                  <th key={key.toString() + "_100"}>100</th>
                </>
              );
            })}
          </tr>
        </thead>
        <tbody>
          <tr>
            {values.map((value, index) => {
              return (
                <td key={index}>
                  <TimeCell
                    setTime={(event, pool, time) => {
                      setTime(swimmer.swimmerId, event, pool, time);
                    }}
                    value={value}
                  ></TimeCell>
                </td>
              );
            })}
          </tr>
        </tbody>
      </ReactTable>
    </div>
  );
};
