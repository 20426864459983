import { cachedFetch } from "./catched_fetch";
import { distancesT, getKeys, seassonsMap, stylesT } from "./values";
import { styleMap } from "./models/Style";
import { EventResult } from "./EventResult";
import { EventResultController } from "./EventResultController";

export const Leverade_SEASON: seassonsMap<number> = {
  "24/25": 7618,
  "23/24": 6653,
  "22/23": 5828,
  "21/22": 4919,
  "20/21": 4130,
  "19/20": 3331,
  "18/19": 2621,
  "17/18": 2620,
  "16/17": 2619,
  "15/16": 2618,
  "14/15": 2617,
  "13/14": 2616,
  "12/13": 2615,
  "11/12": 2614,
  // '10/11': 2613,  // No data found in leverade database from this point
  all: 0,
} as const;

const getSeasonFromSeasonId = (
  id: string | undefined
): keyof typeof Leverade_SEASON => {
  if (!id) {
    return "all";
  }
  let k: keyof typeof Leverade_SEASON;
  for (k in Leverade_SEASON) {
    if (Leverade_SEASON[k].toString() === id) {
      return k;
    }
  }
  return "all";
};

interface LeveradePayloadIf {
  sort: string;
  filter: string;
  "page[number]": number;
  "page[size]": number;
  include?: string;
}

interface LeveradePhaseresultIf {
  competition_name: string;
  custom_fields: Record<string, unknown>;
  date: string;
  discipline_fields: {
    pool_size: "25" | "50";
    chronometer: "electronic" | "manual";
  };
  gender: "male" | "female" | "mixed";
  goal: number;
  location: string;
  official: boolean;
  order: number | null;
  participants_amount: number;
  participants_names: string[] | null;
  position: number | null;
  qualifying: boolean;
  value: number;
  created_at: string;
  updated_at: string;
}

interface RelationIf {
  data: {
    type: string;
    id: string;
  } | null;
}

interface RelationshipsIf {
  category: RelationIf;
  competition: RelationIf;
  office: RelationIf;
  period: RelationIf;
  phase: RelationIf;
  phaseresult: RelationIf;
  resultable: RelationIf;
  season: RelationIf;
  style: RelationIf;
  delegation: RelationIf;
  club: RelationIf;
  profile: RelationIf;
}

interface LeveradeDataIf {
  attributes: LeveradePhaseresultIf;
  relationships: RelationshipsIf;
}

interface LeveradeProfileIf {
  birthdate: string;
  first_name: string;
  gender: "male" | "female";
  last_name: string;
  nationality: string;
  number: string;
}

interface LeveradeClubIf {
  code: string;
  country: string | null;
  detailed_info: string | null;
  email: string;
  headquarter: string | null;
  name: string;
  phone: string;
  selection: boolean;
  created_at: string;
  updated_at: string;
}

interface LeveradeOfficeIf {
  name: string;
}

interface LeveradeLicenseIf {
  type: string;
  number: string;
}

interface LeveradeStyleSeasonIf {
  name: string;
  order: number;
}

interface LeveradeIncludedIf {
  type: string;
  id: string;
  attributes:
  | LeveradeProfileIf
  | LeveradeClubIf
  | LeveradeOfficeIf
  | LeveradePhaseresultIf
  | LeveradeLicenseIf
  | LeveradeStyleSeasonIf;
}

interface LeveradeResponseIf {
  data: LeveradeDataIf[];
  included?: LeveradeIncludedIf[];
}

class Leverade {
  public static STYLE: styleMap<number> = {
    braza: 6,
    espalda: 4,
    estilos: 8,
    libre: 5,
    mariposa: 7,
  };

  static get_request(
    filters: string[],
    callback: (data: LeveradeResponseIf) => void,
    result_size = 50,
    result_include: string | null = null,
    page_number = 1
  ) {
    const filter_string = filters.join(",");
    const payload: LeveradePayloadIf = {
      sort: "value",
      filter: `!resultable[license].id:null,style.discipline.id:38,official:true,value>0,${filter_string}`,
      "page[number]": page_number,
      "page[size]": result_size,
    };
    if (result_include) {
      payload["include"] = result_include;
    }
    const payload_array: string[] = [];
    getKeys(payload).map((key) => {
      payload_array.push(`${key}=${payload[key]}`);
    });
    const query = `https://api.leverade.com/managers/210453/individual_phaseresults?${payload_array.join(
      "&"
    )}`;
    const cacheExpirySeconds = 24 * 60 * 60; // One day in seconds
    cachedFetch(cacheExpirySeconds, query)
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw new Error(res.statusText);
        }
      })
      .then((res) => callback(res))
      .catch((err) => console.log("Error: ", err));
  }

  static get_request_data(
    filters: string[],
    setEventResult: (eventResult: EventResult | undefined) => void,
    result_size = 50,
    result_include: string | null = null,
    page_number = 1
  ) {
    const updateTimeAndData = (queryResult: LeveradeDataIf[]) => {
      if (queryResult.length === 0) {
        const eventResult: EventResult = {
          date: "01/01/1900",
          event: {
            chronometer_type: "E",
            pool_length: 50,
            distance: 50,
            style: "libre",
          },
          participant: {
            born_year: 1900,
            surname: "todo",
            name: "todo",
          },
          season: "all",
          time: 0,
          when: new Date().toLocaleDateString().split(" ")[0],
        };
        setEventResult(eventResult);
        return;
      }
      const date_parts = queryResult[0].attributes.date
        .split(" ")[0]
        .split("-");
      const date_print = `${date_parts[2]}/${date_parts[1]}/${date_parts[0]}`;
      const eventResult: EventResult = {
        date: date_print,
        event: {
          chronometer_type:
            queryResult[0].attributes.discipline_fields.chronometer ===
              "electronic"
              ? "E"
              : "M",
          pool_length:
            queryResult[0].attributes.discipline_fields.pool_size === "25"
              ? 25
              : 50,
          distance: 50,
          style: "libre",
        },
        participant: {
          born_year: 1900,
          surname: "todo",
          name: "todo",
        },
        season: getSeasonFromSeasonId(
          queryResult[0].relationships.season.data?.id
        ),
        time: queryResult[0].attributes.value / 100.0,
        when: new Date().toLocaleDateString().split(" ")[0],
      };
      if (queryResult[0].attributes.competition_name) {
        eventResult.championship = queryResult[0].attributes.competition_name;
      }
      setEventResult(eventResult);
    };
    Leverade.get_request(
      filters,
      (data: LeveradeResponseIf) => {
        updateTimeAndData(data.data);
      },
      result_size,
      result_include,
      page_number
    );
  }

  static find_leverade_participant_profile(
    first_name: string,
    last_name: string,
    birth_year: number,
    callback: (profile: LeveradeProfileIf) => void
  ) {
    const find_profile_callback = function (response_data: LeveradeResponseIf) {
      const found_profiles: LeveradeProfileIf[] = [];
      if (response_data.included) {
        response_data.included.forEach(function (element) {
          if (element.type === "profile") {
            found_profiles.push(<LeveradeProfileIf>element.attributes);
          }
        });
      }
      if (found_profiles.length === 1) {
        callback(found_profiles[0]);
      }
    };
    Leverade.get_request(
      [
        Leverade.get_aproximate_profile_filter(
          first_name,
          last_name,
          birth_year
        ),
      ],
      find_profile_callback,
      25,
      "resultable.profile"
    );
  }

  static get_first_name_fiter(first_name: string) {
    return first_name === "" || first_name.includes(".")
      ? ""
      : `profile.first_name^${first_name.replace(/ /g, "+")},`;
  }

  static get_aproximate_profile_filter(
    first_name: string,
    last_name: string,
    birth_year: number
  ) {
    return `${this.get_first_name_fiter(
      first_name
    )}profile.last_name^${last_name.replace(
      / /g,
      "+"
    )},profile.birthdate>${Leverade.get_last_day_prev_year(
      birth_year
    )},profile.birthdate<${Leverade.get_first_day_next_year(birth_year)}`;
  }

  static get_event_filter(distance: number, style: keyof styleMap<void>) {
    return `goal:${distance},style.id:${Leverade.STYLE[style]}`;
  }

  static get_season_filter(season: keyof typeof Leverade_SEASON) {
    if (season === "all") {
      return "";
    }
    return `season.id:${Leverade_SEASON[season]}`;
  }

  static get_pool_filter(pool: number) {
    return `discipline_fields.pool_size:${pool}`;
  }

  static get_last_day_prev_year(year: number) {
    return `${year - 1}-12-31`;
  }

  static get_first_day_next_year(year: number) {
    return `${year + 1}-01-01`;
  }
}

export class LeveradeParticipant implements EventResultController {
  private readonly profile_filter: string;

  constructor(
    private readonly name: string,
    private readonly surname: string,
    private readonly birth_year: number
  ) {
    this.profile_filter = Leverade.get_aproximate_profile_filter(
      name,
      surname,
      birth_year
    );
  }

  get_best_event_result(
    distance: distancesT,
    style: stylesT,
    pool: 25 | 50,
    chronometer_type: "E" | "M" | null,
    season: keyof seassonsMap<void>,
    setEventResult: (eventResult: EventResult | undefined) => void
  ) {
    return this.get_event_results(
      distance,
      style,
      pool,
      setEventResult,
      chronometer_type,
      1,
      season
    );
  }

  get_unique_id(): string {
    return this.profile_filter;
  }

  get_event_results(
    distance: distancesT,
    style: stylesT,
    pool: 25 | 50,
    setEventResult: (eventResult: EventResult | undefined) => void,
    chronometer_type: "E" | "M" | null = null,
    result_size = 10,
    season: keyof seassonsMap<void> = "all",
    sort_mode = "time",
    sort_direction: 0 | 1 = 1,
    results_limit = 10,
    start_index = 0
  ) {
    const filters = [
      this.profile_filter,
      Leverade.get_event_filter(distance, style),
    ];
    if (pool != null) {
      filters.push(Leverade.get_pool_filter(pool));
    }
    const seasonfilter = Leverade.get_season_filter(season);
    if (seasonfilter !== "") {
      filters.push(seasonfilter);
    }
    const setEventResultWrapper = (
      eventResult: EventResult | undefined
    ): void => {
      if (eventResult) {
        eventResult.participant.name = this.name;
        eventResult.participant.surname = this.surname;
        eventResult.participant.born_year = this.birth_year;
        eventResult.event.style = style;
        eventResult.event.distance = distance;
        eventResult.event.pool_length = pool;
        if (chronometer_type) {
          eventResult.event.chronometer_type = chronometer_type;
        }
      }
      setEventResult(eventResult);
    };
    Leverade.get_request_data(filters, setEventResultWrapper, result_size);
  }
}
