import React, { useEffect, useState } from "react";
import Relay, { getRelayHeader } from "./Relay";
import { Button, Modal } from "react-bootstrap";
import RelayForm, { FormDataType } from "./RelayForm";

import {
  MainModule,
  RelayHelperController,
  RelayId,
  RelaySummaryEntry,
} from "../TeamsRelaysHelper";
import { relayCategoriesT } from "../models/Category";
import { genderT } from "../models/Gender";

type RelaysSessionProps = {
  teamName: string;
  sessionId: number;
  year: number;
  relayHelperCore: MainModule;
  relayHelperController: RelayHelperController;
};

const RelaysSession = ({
  teamName,
  sessionId,
  year,
  relayHelperCore,
  relayHelperController,
}: RelaysSessionProps) => {
  const getSessionOperationController = () => {
    return relayHelperController.getSessionOperationController(
      teamName,
      sessionId.toString(),
      year
    );
  };
  const sessionsSummaryController =
    relayHelperController.getSessionsSummaryController(
      teamName,
      sessionId.toString(),
      year
    );
  const getRelaysSummary = () => {
    const entries: RelaySummaryEntry[] = [];
    const summaryEntries = sessionsSummaryController.getSummary(year);
    for (let i = 0; i < summaryEntries.size(); i++) {
      const entry = summaryEntries.get(i);
      if (entry) {
        entries.push(entry);
      }
    }
    return entries;
  };
  const [sessionSummary, setSessionSumary] = useState<RelaySummaryEntry[]>(
    getRelaysSummary()
  );

  const updateRelaysWasm = () => {
    setSessionSumary(getRelaysSummary());
  };

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const toWasmCategory = (category: relayCategoriesT) => {
    switch (category) {
      case 80:
        return relayHelperCore.RelayCategory._80;
      case 100:
        return relayHelperCore.RelayCategory._100;
      case 120:
        return relayHelperCore.RelayCategory._120;
      case 160:
        return relayHelperCore.RelayCategory._160;
      case 200:
        return relayHelperCore.RelayCategory._200;
      case 240:
        return relayHelperCore.RelayCategory._240;
      case 280:
        return relayHelperCore.RelayCategory._280;
      case 320:
        return relayHelperCore.RelayCategory._320;
    }
  };

  const toWasmStyle = (style: "free" | "medley") => {
    switch (style) {
      case "free":
        return relayHelperCore.Style.FREE;
      case "medley":
        return relayHelperCore.Style.MEDLEY;
    }
  };

  const toEventGenderWasm = (gender: genderT) => {
    switch (gender) {
      case "male":
        return relayHelperCore.EventGender.MALE;
      case "female":
        return relayHelperCore.EventGender.FEMALE;
      case "mix":
        return relayHelperCore.EventGender.MIXED;
    }
  };

  const toWasmPool = (pool: 25 | 50) => {
    return pool === 25
      ? relayHelperCore.TimesPool._25
      : relayHelperCore.TimesPool._50;
  };

  const addRelay = (relayData: FormDataType) => {
    getSessionOperationController().addRelay({
      event: {
        style: toWasmStyle(relayData.style),
        distance: relayData.distance,
        gender: toEventGenderWasm(relayData.gender),
      },
      category: toWasmCategory(relayData.category),
      pool: toWasmPool(25),
    });
    updateRelaysWasm();
  };

  return (
    <>
      <div>
        {sessionSummary.map((relay, index) => (
          <Relay
            teamName={teamName}
            sessionId={sessionId}
            year={year}
            relayHelperCore={relayHelperCore}
            relayHelperController={relayHelperController}
            relayId={relay.id}
            removeRelay={(relayId: RelayId) => {
              getSessionOperationController().deleteRelay(relayId);
              updateRelaysWasm();
            }}
            bgClass={index % 2 == 0 ? "full-transparent" : "semi-transparent"}
            key={getRelayHeader(relay.id, relayHelperCore)}
          />
        ))}
      </div>
      <div>
        <Button variant="secondary" onClick={handleShow}>
          Añadir relevo
        </Button>
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Añade nuevo relevo</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <RelayForm
            handleSubmit={(formData: FormDataType) => {
              addRelay(formData);
              handleClose();
            }}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancelar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default RelaysSession;
