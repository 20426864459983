import React from "react";

export type EventRendererValueTypes = {
  type: string;
  distance: number;
  style: string;
  gender: string;
};

export type EventRendererProps = {
  values: EventRendererValueTypes;
};

const EventRenderer = ({ values }: EventRendererProps) => {
  const prefix = values.type === "relay" ? "4x" : "";
  const distance = values.distance;
  const style = values.style;
  const gender = values.gender;
  return <>{prefix + distance.toString() + " " + style + " " + gender}</>;
};

export default EventRenderer;
