import TeamsRelays from "./TeamsRelays.mjs";
import { MainModule } from "./TeamsRelaysHelper";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import TeamsRelaysWASM from "./TeamsRelays.wasm";

let core: MainModule | null = null;

export const getInstance = async (): Promise<MainModule> => {
  if (core === null) {
    const fetchWasm = async () => {
      return await TeamsRelays({
        locateFile: () => {
          return TeamsRelaysWASM;
        },
      });
    };
    try {
      core = await fetchWasm();
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
  if (core === null) {
    throw new Error("core is null");
  }
  return core;
};
